import "./App.css";
import Label from "./components/Label";
import React, { useEffect, useState } from "react";


const columns = 3;
const rows = 7;
const getWineData = (wine) => {
  const [producer, model, type, quantity, alcohol, country, barcode] = wine.split(", ");
  return {
    producer,
    model,
    type,
    quantity: quantity ? quantity.replace("ml", "ML") : "",
    alcohol: alcohol ? alcohol.replace("% alcool", "% alc. vol") : "",
    country,
    barcode,
  };
};

function App() {
  const [pageToPrint, setPageToPrint] = useState(1);
  const [postContent, setPostContent] = useState(""); // Declare a state variable...
  const wineData =
    pageToPrint &&
    postContent
      .split("\n")
      .filter((x) => x)
      .map((wine) => getWineData(wine))[pageToPrint - 1];
  document.title = `Eticheta ${wineData?.producer || ""} ${wineData?.model || ""}`;
  return (
    <>
      <div className="control-group">
        <div>ex: Schola Sarmenti, Corimei Primitivo passito 2022 , vin rosu dulce, 750 ml, 16% alcool, Produs si imbuteliat Italia - 5940597771226</div>
        <textarea
          name="postContent"
          value={postContent} // ...force the input's value to match the state variable...
          onChange={(e) => setPostContent(e.target.value)} // .
          rows={2}
          cols={160}
        />
      </div>
      {postContent.split(",").length == 7 && <Page printList={Array(columns * rows).fill(wineData)} />}
    </>
  );
}
// wineListData.map((wine, index) => {
//   return <Page key={index} printList={Array(columns * rows).fill(wine)} />;
// })}

function Page({ printList }) {
  const paddingX = 7.279;
  const paddingY = 15.055;
  return (
    <div
      className="page"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        height: `${297}mm`,
        width: "210mm",
        paddingLeft: `${paddingX}mm`,
        paddingRight: `${paddingX}mm`,
        paddingTop: `${paddingY}mm`,
        paddingBottom: `${paddingY}mm`,
        margin: "0",
      }}
    >
      {printList.map((wine, index) => {
        console.log(wine);
        return (
          <div
            key={index}
            style={{
              position: "relative",
            }}
          >
            <Label wine={wine} />
          </div>
        );
      })}
    </div>
  );
}

export default App;
